body {
  margin: 0px;
  padding: 0px;

  background-color: #d2c1b056;
  font-family: 'Montserrat', sans-serif;
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  outline: none;
  border: none;
}

input {
  outline: none;
  border: none;
}

.logo img {
  width: 90px;
}

.a-div {
  padding-top: 30px;
  padding-bottom: 25px;
  background-color: #000000;
  border-top: 1px solid #37251b8a;
}

#main {

  width: 100%;
  height: 768px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./images/background.jpg');
  top: 90px;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;

  z-index: 1;
  background-color: transparent;
  transition: 0.6s ease-in-out;
}

nav.active {
  box-shadow: 5px 10px 30px rgba(228, 188, 132, 0.342);
  background-color: #ffffff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

nav ul {
  display: flex;
}

.active {
  background-color: #80411e;
  color: #ffffff;
  box-shadow: 5px 10px 30px rgba(228, 188, 132, 0.445);
  transition: all ease 0.2s;
}

nav ul li a {
  font-family: calibri;
  height: 38px;
  line-height: 43px;
  margin: 3px;
  padding: 0px 22px;
  display: flex;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  color: #000000;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}

nav ul li a:hover {
  background-color: #80411e;
  color: #ffffff;
  box-shadow: 5px 10px 30px rgba(228, 188, 132, 0.342);
  transition: all ease 0.2s;
}

nav .menu-btn,
.menu-icon {
  display: none;
}

.name {
  font-family: 'Montserrat';
  width: 500px;
  position: absolute;
  left: 22%;
  top: 50%;
  transform: translate(-22%, -50%);
}

.name span {
  color: #755b37;
}

.name .details {
  color: #969696;
}

.name h2 {
  font-family: 'Montserrat';
  font-size: 40px;
  margin: 0px;
  letter-spacing: 2px;
  color: #000000;
}

.header-btns {
  display: flex;
  margin-top: 40px;
}

.cv-btn {
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #755b37;
  box-shadow: 5px 10px 30px rgba(150, 111, 39, 0.185);
  border-radius: 10px;
  color: #ffffff;
}

.cv-btn:hover {
  background-color: #42331f;
  transition: all ease 0.5s;
  color: #ffffff;
}

.f-heading h1 {
  color: #FFFFFF;
  font-size: 3rem;
  margin: 0px;
  padding: 0px;

}

.f-heading p {
  color: rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}

.f-heading {
  text-align: center;
  margin-top: 60px;
  margin-bottom: -80px;
}

#about {
  margin-top: 100px;
  width: 100%;
  height: 120vh;
  background-color: #f3fdff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 5% 0px 5%;
  position: relative;
}

.about-text {
  width: 45%;
  color: #fff;
  list-style: circle;
}

.about-text h2 {
  font-size: 2rem;
  color: #00b7ff;
  font-weight: 500;
  margin: 0px;
  padding: 0px;
}

.about-text p {
  width: 80%;
  font-size: 1.2rem;
  color: #272727;

}

.about-text button {
  margin-top: 20px;
  width: 140px;
  height: 45px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  background-color: #00b7ff;
}

.about-text button:hover {
  background-color: rgb(0, 132, 255);
  transition: all ease 0.3s;
  color: #ffffff;
}

.about-image {
  width: 50%;
}

.about-image img {
  width: 600px;
}

#features {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;

}

.a-container {
  display: flex;
  justify-content: center;
  align-content: center;


}

.a-box {
  background-color: #ffffff62;
  width: 300px;
  height: 500px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
  position: relative;
}

.a-b-img {
  width: 100%;
  height: 60%;

}

.a-b-img img {
  padding: 15px;
  width: 100%;
  height: 100%;
  border-radius: 10%;

}

.a-box:hover {
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
}

#presentaion {
  padding-top: 30px;
  width: 100%;
  height: 768px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(images/presentation.png);
  text-align: center;
}

#presentaion h1 {
  font-size: 2rem;
}

#services {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.s-heading h1 {
  color: #FFFFFF;
  font-size: 3rem;
  margin: 0px;
  padding: 0px;

}

.s-heading p {
  color: rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}

.s-heading {
  text-align: center;
  margin: 20px 0px;
}

.s-box {
  background-color: #ffffff;
  width: 350px;
  height: 470px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.b-container {
  display: flex;
  justify-content: center;
  align-content: center;


}

.s-b-img {
  width: 100%;
  height: 50%;

}

.s-b-img img {
  width: 100%;
  height: 100%;

}

.s-b-text {
  width: 100%;
  height: 40%;
  background-color: #ffffff00;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.s-b-text p {
  margin: 0px;
  color: #37251b;
  font-size: 1.1rem;
  font-family: calibri;
  display: block;
  display: -webkit-box;
  max-width: 80%;
  margin-top: 25px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.s-b-text p:hover {
  color: #37251b;
}

.s-b-text a {
  margin-top: 15px;
}

.s-type {
  font-family: calibri;
  color: #37251b;
  background-color: #ffffff62;
  padding: 10px 15px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2);
}


#contact {
  background-color: #00b7ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}

#contact h3 {
  font-size: 2.5rem;
  margin: 20px;
  color: #fff;
}

.contact-input {
  width: 400px;
  height: 50px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  border-radius: 50px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.15);
}

.contact-input input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  color: #242425;
}

.contact-input a {
  width: 200px;
  height: 35px;
  background-color: #00b7ff;
  color: #FFFFFF;
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  margin: auto 10px;
  border-radius: 20px;
  font-weight: 500;
  transition: 0.4s ease-out;
}

.contact-input a:hover {
  background-color: #0099ff;
}

@media(max-width:1080px) {
  #main {
    background-size: 1150px !important;

  }

  .name {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  #features {
    height: auto;
  }

  .a-container {
    display: flex;


    flex-wrap: wrap;
  }

  .a-box {
    flex-grow: 1;
  }

  #services {
    height: auto;
  }

  .b-container {
    flex-wrap: wrap;
  }

  .s-box {
    flex-grow: 1;
  }

  .s-b-img img {
    object-fit: cover;
  }

  .a-b-img img {
    width: 100%;

    height: 100%;
    border-radius: 10%;
  }



}

@media(max-width:1033px) {
  .a-container {
    max-width: 375px;


    flex-wrap: wrap;
  }
}

@media(max-width:970px) {
  .main {
    background-image: none !important;

  }

  .name h2 {
    font-size: 28px;
    text-align: center;
  }

  .name {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .cv-btn {
    margin-left: 38%;
    margin-top: 20px;
  }

  #about {
    justify-content: center;
    padding-top: 0px;
    text-align: center;

    margin-top: 0px;
  }

  .about-text {
    width: 90%;
  }

  .about-text h1 {
    font-size: 4rem;
  }

  .about-text p {
    width: 100%;
  }

  #features {
    margin-bottom: 100px;
  }

  #presentaion {
    display: none;
  }
}

@media(max-width:600px) {
  .name {
    width: 60%;
  }

}

nav .menu.menu-closed {
  display: none;
}

.scroll {
  left: 42.5%;
}


.menu.menu-closed {
  display: none;
}

@media(max-width:1100px) {
  .menu-icon {
    display: block;
    margin-top: 5px;
  }

  .menu-active {
    margin-top: 30px;
  }

  .cv-btn {
    margin-left: 38%;

  }

  nav {
    justify-content: space-between;
    height: 60px;
    padding: 0px 30px;
  }

  .logo img {
    width: 70px;
  }

  .header-btns {
    margin: 0;
  }

  .menu {
    display: none;
    position: absolute;
    top: 65px;
    left: 0px;
    background-color: #FFFFFF;
    border-bottom: 4px solid #37251b;
    width: 100%;
    padding: 0px;
    margin: 0px;
    z-index: 3;
    
  }

  .menu li {
    width: 100%;
  }

  nav .menu li a {
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 0px;
    padding: 25px;
    border: 1px solid rgba(38, 38, 38, 0.03);
  }

  nav .menu-icon {
    cursor: pointer;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }

  nav .menu-icon .nav-icon {
    background-color: #333333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  nav .menu-icon .nav-icon:before,
  nav .menu-icon .nav-icon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all ease-out 0.2s;
    width: 100%;
  }

  nav .menu-icon .nav-icon:before {
    top: 5px;
  }

  nav .menu-icon .nav-icon:after {
    top: -5px;
  }

  nav .menu-btn:checked~.menu-icon .nav-icon {
    background: transparent;
  }

  nav .menu-btn:checked~.menu-icon .nav-icon:before {
    transform: rotate(-45deg);
    top: 0;
  }

  nav .menu-btn:checked~.menu-icon .nav-icon:after {
    transform: rotate(45deg);
    top: 0;
  }

  nav .menu-btn {
    display: none;
  }

  nav .menu-btn:checked~.menu {
    display: block;
  }

}

@media(max-width:400px) {
  .s-box {

    width: 100%;
    height: 400px;
  }
}

.rounded-image {
  border-radius: 50%;
  max-width: 150px;

}

@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);


form {
  max-width: 410px;
  margin: 50px auto;

}

.feedback-input {
  color: white;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  border-radius: 5px;
  line-height: 22px;
  background-color: transparent;
  border: 2px solid #ffffff;
  transition: all 0.3s;
  padding: 13px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  outline: 0;
}

.feedback-input:focus {
  border: 2px solid #ffffff7a;
}

textarea {
  height: 150px;
  line-height: 150%;
  resize: vertical;
}

[type="submit"] {
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  color: #201610;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 0.3s;
  margin-top: -4px;
  font-weight: 700;
}

[type="submit"]:hover {
  background: #ffffffb4;
}

.container {
  display: flex;
  width: 70%;
  Border: 1px dashed rgba(0, 0, 0, 0.247);
  box-shadow: 5px 20px 30px rgba(0, 0, 0, 0.356);
  border-radius: 5px;
  margin: auto;
  margin-bottom: 50px;
  background-color: #7c5b471f;
}

.contact-info {
  background-color: #201610;
  flex-basis: 50%;
  margin-right: 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: white;

}

.contact-info2 {
  margin-top: 40px;
  margin-left: 25%;
  margin-right: 5%;



}

.contact-info3 {

  padding-top: 75px;
  width: 100%;
  text-align: center;


}

#contact-form {
  width: 300px;
}

@media(max-width:1100px) {
  .container {

    width: 90%;
  }

  .contact-info2 {

    margin-left: 15%;




  }
}

@media screen and (max-width: 850px) {

  #contact-form {
    width: 200px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    flex-direction: column;
    min-width: 300px;
  }

  .cv-btn {
    margin-left: 25%;

  }

  .contact-info {

    margin-right: 0;
    margin-bottom: 20px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;

  }

  .contact-info2 {


    margin-bottom: 75px;

  }
}

.contact-info3 h2 {
  text-align: center;
}

h2 {
  text-align: center;
}

.form-group {

  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 10px;
  background-color: #ffffffb7;
  border: 1px solid #ffffffb7;
  border-radius: 4px;
}

button[type="submit"] {
  background-color: #755b37;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #4b3a23;
}


.notification {
  font-size: 12px;
  color: red;
}


/* About Section Styles */

.about-section {
  display: flex;
  align-items: flex-start;
  width: 80%;
  margin-left: auto;
  padding-bottom: 75px;
}

.image-wrapper {
  max-width: 50%;

}

.profileImage {

  border-radius: 5px;
}

.image-wrapper img {
  max-height: 600px;
  width: auto;
  display: block;
}

.text-wrapper {
  padding-top: 100px;
  max-width: 50%;
  padding-left: 20px;

}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: left;
}

.text-wrapper p {
  width: 95%;
}

p {
  font-size: 16px;
}

@media (max-width: 970px) {
  .about-section {
    flex-direction: column;
  }

  .image-wrapper,
  .text-wrapper {
    padding-top: 10px;
    max-width: 100%;
    margin: auto;

  }

  .text-wrapper {
    padding-left: 0;
    text-align: center;
    margin-top: 0px;
    width: 95%;
  }

  .text-wrapper h2 {
    text-align: center;

  }
}

@media (max-width: 600px) {
  .text-wrapper p {
    width: 100%;

  }
}

@media (max-width: 1200px) {
  .about-section {

    width: 100%;

  }
}

@media(max-width:900px) {
  .profileImage {
    max-width: 95%;
    margin: auto;
  }
}