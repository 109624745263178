.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #55442a;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

/* Optional styles to customize the appearance */
.switch-container {
  margin-top: 5px;

}

.switch-container2 {
  margin-top: 5px;

}

.switch input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch label {
  color: #666;
  font-size: 14px;
  user-select: none;
  padding-left: 10px;
}

.language-label {
  position: absolute;
  top: 8px;
  left: 27px;
  color: #666;
  font-size: 14px;
  padding: 0 6px;
}

.language-label:first-child {
  left: 4px;
}

.language-label:last-child {
  right: 4px;
}

@media (max-width:1100px) {
  .switch-container {
    margin-top: 5px;

  }

}